//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../..";
import router from '@/router'


class CompraServices {



  compraActualizar(data) {
    return http.post("/inventario/compra/compraActualizar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  notaCreditoActualizar(data) {
    return http.post("/inventario/compra/notaCreditoActualizar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  ordenCompraActualizar(data) {
    return http.post("/inventario/compra/ordenCompraActualizar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }




  reporteHistorialCompras(params) {
    return http.get("/inventario/compra/reporteHistorialCompras", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  productoCompraListarPaginado(params) {
    return http.get("/inventario/compra/productoCompraListarPaginado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  productoNotaCreditoListarPaginado(params) {
    return http.get("/inventario/compra/productoNotaCreditoListarPaginado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  

  
  productoOrdenCompraListarPaginado(params) {
    return http.get("/inventario/compra/productoOrdenCompraListarPaginado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  
  anularOrdenCompra(data) {
    return http.post("/inventario/compra/ordenCompraAnular", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  anularCompra(data) {
    return http.post("/inventario/compra/compraAnular", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  anularNotaCredito(data) {
    return http.post("/inventario/compra/notaCreditoAnular", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  

  validarCompra(data) {
    return http.post("/inventario/compra/compraValidar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
 
  validarNotaCredito(data) {
    return http.post("/inventario/compra/notaCreditoValidar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteCxpGlobalTodos(params) {
    return http.get("/inventario/compra/reporteCxpGlobalTodos", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteCxpProveedorGrupo(params) {
    return http.get("/inventario/compra/reporteCxpProveedorGrupo", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  reporteCxpGlobalGrupo(params) {
    return http.get("/inventario/compra/reporteCxpGlobalGrupo", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  ReporteCXPProveedorDetallado(params) {
    return http.get("/inventario/compra/ReporteCXPProveedorDetallado", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  rptComprasCuentasContable(params) {
    return http.get("/inventario/compra/rptComprasCuentasContable", { params })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  


}

export default new CompraServices();